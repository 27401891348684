import Layout from "../../components/layout";
import PageTitle from "../../components/pagetitle";
import Seo from "../../components/seo";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const PasadenaPage = () => (
    <Layout>
        <PageTitle title="Managed Services and Software In Pasadena, MD" page="PageTitleAbout" />
        <Seo title="Managed Services and Software In Pasadena, MD"
             description="New Vertical offers Managed Services and Software services to Pasadena, MD.  We can support nearly all consumer and business technology."
             pathname={"/locations/pasadena/"}/>
        <div className="content-page-wrapper">
            <div className="page-intro">
                <StaticImage
                    style={{
                        gridArea: "1/1",
                    }}
                    layout="fullWidth"
                    loading="eager"
                    aspectRatio={2 / 1}
                    alt="Our team will help"
                    src={"../../images/pasadena.jpeg"}
                    formats={["auto", "jpg", "avif"]}
                />
                <h2>Managed Services and Software In Pasadena Maryland</h2>
                <p>Pasadena, Maryland is a city on the Chesapeake Bay in Anne Arundel County that’s home to many technology companies. From small start-ups to large corporate operations, Pasadena has a thriving technology industry that offers a variety of services.</p>

                <p> One of the most popular technology companies in Pasadena is Digital Harbor Technology. They provide custom software and web development; international projects development; IT consulting; and much more. They also offer cloud solutions and development services, as well as managed hosting, IT infrastructure, and software as a service. Digital Harbor Technology has a team of professionals that specializes in developing solutions to fit the unique challenges of businesses, while providing reliable quality and exceptional customer service.</p>

                <p> Pasadena also boasts a number of small businesses offering technology services, ranging from website design and development to mobile app development. Some of these local companies include Web Couture Solutions and Mobile App Designers. Both companies specialize in creating effective online experiences for customers. Whether it’s a one-page website or a complex enterprise mobile application, these companies can help businesses create an immersive web experience to help businesses reach their customers.</p>

                <p> In addition to these locally-owned businesses, Pasadena is home to several major technology service providers. Humana is one of the largest, offering a wide range of products and services that include health insurance, pharmacy services, and digital health solutions. They have a team of technology experts who can build customized solutions for businesses in Pasadena and beyond.</p>

                <p> Businesses in Pasadena also have access to data security services from Blue Coat Systems. They provide world-class solutions that protect critical data from risks like hacking, data theft, and malicious software. They also offer identity protection services for companies that need watertight security for their data.</p>

                <p> Pasadena has come a long way from its small-town roots and is now home to a growing technology services sector. With quality services from local, regional and international companies, businesses in Pasadena have access to the best in the world when it comes to technology solutions. Whether you’re in need of software development, website design, or data security services, there’s a Pasadena-based service provider ready to assist you.</p>
            </div>
        </div>
    </Layout>
)

export default PasadenaPage